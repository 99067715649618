<template>
  <div>
    <item-autocomplete
      v-model="innerValue.author"
      :load="loadDoctors"
      item-text="fullName"
      item-value="id"
      hide-details
      label="Автор"
      placeholder="Выберите автора"
      name="doctor-autocomplete"
      hide-no-data
    />
    <v-checkbox
      label="Показывать этот пост на странице доктора"
      v-model="innerValue.showOnAuthorPage"
    />

    <br>

    <item-autocomplete
      v-model="innerValue.organization"
        :load="loadClinics"
        item-text="name"
        item-value="id"
        label="Клиника"
        hide-no-data
        placeholder="Выберите клинику"
        name="clinic-autocomplete"
        hide-details
    />
    <v-checkbox
      label="Показывать этот пост на странице клиники"
      v-model="innerValue.showOnClinicPage"
    />

    <br>

  </div>
</template>

<script>
import doctorsService from '../../services/doctors'
import clinicsService from '../../services/clinics'
import ItemAutocomplete from './ItemAutocomplete.vue'
import isEqual from 'lodash/isEqual'

export default {
  components: {
    ItemAutocomplete
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      innerValue: this.value ? { ...this.value } : {}
    }
  },
  watch: {
    value (value) {
      if (!isEqual(value, this.innerValue)) {
        this.innerValue = value
      }
    },
    innerValue: {
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    }
  },
  methods: {
    async loadDoctors (search) {
      const { items } = await doctorsService.getAll({ search })
      return items
    },
    async loadClinics (search) {
      const { items } = await clinicsService.getAll({ search })
      return items
    },
  }
}
</script>
